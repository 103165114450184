import {type Instruction} from '@backstage/instructions';
import {globalVariable, localVariable, storage} from '../../helpers';
import type {JSONObject} from '../../types';
import {getFlows, triggerTransformation} from './transformations/converter';
import {BroadcastFunction} from './transformations/node.types';
import type {AppPage} from './types';

interface ProcessInstructionsArgs {
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /** Function to execute to get details about the current page */
  getCurrentPage: () => AppPage | undefined;
  /** The batch of instructions to process */
  instructions: Instruction[];
  /** Id of the show whose instructions are being handled. */
  showId: string;
  /** Function to broadcast a new instruction into the broker */
  broadcast: BroadcastFunction;
}

/**
 * Transforms instructions as needed for reactivity (e.g. `variable:set` to
 * `variable:on-set`, `CustomInstruction:broadcast` to
 * `CustomInstruction:on-broadcast`) and then triggers the flow transformations
 * for each instruction.
 */
export function processInstructionFlows(args: ProcessInstructionsArgs): void {
  const {domainName, getCurrentPage, instructions, showId, broadcast} = args;
  const flows = getFlows(domainName);
  const actions = preprocessInstructionBatch(instructions);
  const structure = getCurrentPage()?.structure;
  // Process all the variables manipulations BEFORE allowing flows to react to
  // variable setting (or unsetting). It is necessary they are all processed
  // before flows which may be reacting to those instructions are executed in
  // order to ensure consistent flow behavior.
  actions.forEach((inst) => {
    if (inst.type === 'Global:variable:on-set') {
      setVariables(inst.meta, globalVariable(showId));
    } else if (inst.type === 'variable:on-set') {
      setVariables(inst.meta, localVariable(showId));
    }
  });
  // prepend the variable manipulation instructions to the list of actions then
  // process the flows associated instructions in the batch
  actions.forEach((inst) => {
    const flowStartNodes = flows[inst.type];
    if (structure && Array.isArray(flowStartNodes)) {
      flowStartNodes.forEach((node) => {
        triggerTransformation(node, inst, broadcast, showId, structure);
      });
    }
  });
}

/**
 * Transforms the `type` of `Instruction` to match `NodeType.listener` naming
 * convention.
 * - `variable:set` becomes `variable:on-set`
 * - `variable:unset` becomes `variable:on-unset`
 * - `CustomInstruction:broadcast` becomes `CustomInstruction:on-broadcast`
 * @private exported for tests
 */
export function preprocessInstructionBatch(
  instructions: Instruction[]
): Instruction[] {
  return instructions.map((action) => {
    if (action.type === 'CustomInstruction:broadcast') {
      return {
        ...action,
        type: 'CustomInstruction:on-broadcast',
      };
    } else if (
      action.type.endsWith('variable:set') ||
      action.type.endsWith('variable:unset')
    ) {
      return {
        ...action,
        type: action.type.replace(/^(.*variable):((un)?set)/, '$1:on-set'),
      };
    } else {
      return action;
    }
  });
}

/** For a given object, modify `storage` for each key value pair */
const setVariables = (variables: JSONObject, prefix: string): void => {
  Object.entries(variables).forEach(([key, value]) => {
    if (value === null) {
      storage.removeItem(`${prefix}${key}`);
    } else {
      storage.setItem(`${prefix}${key}`, JSON.stringify(value));
    }
  });
};
